const regions = {
  'ru-ad': 'Адыгея',
  'ru-ba': 'Башкортостан',
  'ru-bu': 'Бурятия',
  'ru-al': 'Алтай',
  'ru-da': 'Дагестан',
  'ru-in': 'Ингушетия',
  'ru-kb': 'Кабардино-Балкария',
  'ru-kl': 'Калмыкия',
  'ru-kc': 'Карачаево-Черкесия',
  'ru-krl': 'Карелия',
  'ru-ko': 'Республика Коми',
  'ru-me': 'Марий Эл',
  'ru-mo': 'Мордовия',
  'ru-sa': 'Якутия (Саха)',
  'ru-se': 'Северная Осетия',
  'ru-ta': 'Татарстан',
  'ru-ty': 'Тува',
  'ru-ud': 'Удмуртия',
  'ru-kk': 'Хакасия',
  'ru-ce': 'Чеченская республика',
  'ru-chv': 'Чувашия',
  'ru-alt': 'Алтайский край',
  'ru-kda': 'Краснодарский край',
  'ru-kya': 'Красноярский край',
  'ru-pri': 'Приморский край',
  'ru-sta': 'Ставропольский край',
  'ru-kha': 'Хабаровский край',
  'ru-amu': 'Амурская область',
  'ru-ark': 'Архангельская область',
  'ru-ast': 'Астраханская область',
  'ru-bel': 'Белгородская область',
  'ru-bry': 'Брянская область',
  'ru-vla': 'Владимирская область',
  'ru-vgg': 'Волгоградская область',
  'ru-vol': 'Вологодская область',
  'ru-vor': 'Воронежская область',
  'ru-iva': 'Ивановская область',
  'ru-irk': 'Иркутская область',
  'ru-kal': 'Калининградская область',
  'ru-klu': 'Калужская область',
  'ru-kam': 'Камчатский край',
  'ru-kem': 'Кемеровская область',
  'ru-kir': 'Кировская область',
  'ru-kos': 'Костромская область',
  'ru-kgn': 'Курганская область',
  'ru-krs': 'Курская область',
  'ru-len': 'Ленинградская область',
  'ru-lip': 'Липецкая область',
  'ru-mag': 'Магаданская область',
  'ru-mos': 'Московская область',
  'ru-mur': 'Мурманская область',
  'ru-niz': 'Нижегородская область',
  'ru-ngr': 'Новгородская область',
  'ru-nvs': 'Новосибирская область',
  'ru-oms': 'Омская область',
  'ru-ore': 'Оренбургская область',
  'ru-orl': 'Орловская область',
  'ru-pnz': 'Пензенская область',
  'ru-per': 'Пермский край',
  'ru-psk': 'Псковская область',
  'ru-ros': 'Ростовская область',
  'ru-rya': 'Рязанская область',
  'ru-sam': 'Самарская область',
  'ru-sar': 'Саратовская область',
  'ru-sak': 'Сахалинская область',
  'ru-sve': 'Свердловская область',
  'ru-smo': 'Смоленская область',
  'ru-tam': 'Тамбовская область',
  'ru-tve': 'Тверская область',
  'ru-tom': 'Томская область',
  'ru-tul': 'Тульская область',
  'ru-tyu': 'Тюменская область',
  'ru-uly': 'Ульяновская область',
  'ru-che': 'Челябинская область',
  'ru-zab': 'Забайкальский край',
  'ru-yar': 'Ярославская область',
  'ru-mow': 'Москва',
  'ru-spb': 'Санкт-Петербург',
  'ru-jew': 'Еврейская автономная область',
  'ru-km': 'Крым',
  'ru-nen': 'Ненецкий автономный округ',
  'ru-khm': 'Ханты-Мансийский автономный округ',
  'ru-chu': 'Чукотский автономный округ',
  'ru-yam': 'Ямало-Ненецкий автономный округ',
  'ru-sev': 'Севастополь',
};

export default regions;
